@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-Light.woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-LightItalic.woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-Regular.woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-Italic.woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-Bold.woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('fonts/NotoSans/NotoSans-BoldItalic.woff2');
}
