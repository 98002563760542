@font-face {
  font-family: 'Unity-Font-Icon-20200218';
  src: url('fonts/UnityFontIcon/Unity-Font-Icon-20200218.woff?up3u1x') format('woff');
  /*src:  url('fonts/UnityFontIcon/Unity-Font-Icon-20200218.eot?up3u1x');*/
  /*src:  url('fonts/UnityFontIcon/Unity-Font-Icon-20200218.eot?up3u1x#iefix') format('embedded-opentype'),*/
  /*  url('fonts/UnityFontIcon/Unity-Font-Icon-20200218.ttf?up3u1x') format('truetype'),*/
  /*  url('fonts/UnityFontIcon/Unity-Font-Icon-20200218.svg?up3u1x#Unity-Font-Icon-20200218') format('svg');*/
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='unity_'],
[class*=' unity_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Unity-Font-Icon-20200218' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unity_join_intersect:before {
  content: '\ea3f';
}
.unity_expand_vertical:before {
  content: '\ea26';
}
.unity_aggregation:before {
  content: '\ea27';
}
.unity_bookmark:before {
  content: '\ea28';
}
.unity_branch:before {
  content: '\ea29';
}
.unity_breakdown:before {
  content: '\ea2a';
}
.unity_calculate:before {
  content: '\ea2b';
}
.unity_change:before {
  content: '\ea2c';
}
.unity_columns:before {
  content: '\ea2d';
}
.unity_data_input:before {
  content: '\ea2e';
}
.unity_data_output:before {
  content: '\ea2f';
}
.unity_error_circle:before {
  content: '\ea30';
}
.unity_details:before {
  content: '\ea31';
}
.unity_expand:before {
  content: '\ea32';
}
.unity_join_intersect_left:before {
  content: '\ea33';
}
.unity_join_intersect_right:before {
  content: '\ea34';
}
.unity_join_left:before {
  content: '\ea35';
}
.unity_join_outline:before {
  content: '\ea36';
}
.unity_join_right:before {
  content: '\ea37';
}
.unity_join_union:before {
  content: '\ea38';
}
.unity_join:before {
  content: '\ea39';
}
.unity_latitude:before {
  content: '\ea3a';
}
.unity_loading_spinner:before {
  content: '\ea3b';
}
.unity_longitude:before {
  content: '\ea3c';
}
.unity_metronome:before {
  content: '\ea3d';
}
.unity_numeric_formula:before {
  content: '\ea3e';
}
.unity_numeric_series:before {
  content: '\ea40';
}
.unity_palette:before {
  content: '\ea41';
}
.unity_parameters_outline:before {
  content: '\ea42';
}
.unity_plug:before {
  content: '\ea43';
}
.unity_python:before {
  content: '\ea44';
}
.unity_rows:before {
  content: '\ea45';
}
.unity_parameters:before {
  content: '\ea46';
}
.unity_sound_off:before {
  content: '\ea47';
}
.unity_shapes:before {
  content: '\ea48';
}
.unity_sound_on:before {
  content: '\ea49';
}
.unity_sync:before {
  content: '\ea4a';
}
.unity_text_formula:before {
  content: '\ea4b';
}
.unity_warning_circle_fill:before {
  content: '\ea4c';
}
.unity_warning_triangle_fill:before {
  content: '\ea4d';
}
.unity_warning_triangle_outline:before {
  content: '\ea4e';
}
.unity_zoom_in:before {
  content: '\ea4f';
}
.unity_zoom_out:before {
  content: '\ea50';
}
.unity_warning_circle_outline:before {
  content: '\ea51';
}
.unity_resolution:before {
  content: '\ea25';
}
.unity_app_menu:before {
  content: '\ea15';
}
.unity_ellipses_horizontal:before {
  content: '\ea16';
}
.unity_ellipses_vertical:before {
  content: '\ea17';
}
.unity_file_archive:before {
  content: '\ea18';
}
.unity_file:before {
  content: '\ea19';
}
.unity_paste:before {
  content: '\ea1a';
}
.unity_pin:before {
  content: '\ea1b';
}
.unity_sort_down:before {
  content: '\ea1c';
}
.unity_sort_up:before {
  content: '\ea1d';
}
.unity_sort:before {
  content: '\ea1e';
}
.unity_trash_delete:before {
  content: '\ea1f';
}
.unity_trash_empty:before {
  content: '\ea20';
}
.unity_trash_full:before {
  content: '\ea21';
}
.unity_trash_restore:before {
  content: '\ea22';
}
.unity_three_d:before {
  content: '\ea23';
}
.unity_image:before {
  content: '\ea24';
}
.unity_pivot:before {
  content: '\ea14';
}
.unity_Save_as:before {
  content: '\e9a9';
}
.unity_Save:before {
  content: '\ea08';
}
.unity_scatter_chart:before {
  content: '\e99f';
}
.unity_ranking_vert_chart:before {
  content: '\ea0f';
}
.unity_ranking_horiz_chart:before {
  content: '\ea10';
}
.unity_histogram_chart:before {
  content: '\ea11';
}
.unity_db_progress:before {
  content: '\ea12';
}
.unity_db_aws:before {
  content: '\ea13';
}
.unity_screencapture:before {
  content: '\ea0e';
}
.unity_flag:before {
  content: '\ea0d';
}
.unity_thumbs_down_filled:before {
  content: '\ea0a';
}
.unity_thumbs_up_filled:before {
  content: '\ea0b';
}
.unity_circle_filled:before {
  content: '\ea0c';
}
.unity_adv_forecast:before {
  content: '\e9fd';
}
.unity_adv_learn:before {
  content: '\e9fe';
}
.unity_adv_scoring:before {
  content: '\e9ff';
}
.unity_adv_what_if:before {
  content: '\ea00';
}
.unity_db_dynamo:before {
  content: '\ea01';
}
.unity_db_mqtt:before {
  content: '\ea02';
}
.unity_db_msaccess:before {
  content: '\ea03';
}
.unity_db_msdynamics:before {
  content: '\ea04';
}
.unity_db_timescale:before {
  content: '\ea05';
}
.unity_db_websockets:before {
  content: '\ea06';
}
.unity_hide:before {
  content: '\ea07';
}
.unity_show:before {
  content: '\ea09';
}
.unity_fast_forward:before {
  content: '\e9f7';
}
.unity_pause:before {
  content: '\e9f8';
}
.unity_play:before {
  content: '\e9f9';
}
.unity_skip_forward:before {
  content: '\e9fa';
}
.unity_skip_rewind:before {
  content: '\e9fb';
}
.unity_stop:before {
  content: '\e9fc';
}
.unity_db_ibm:before {
  content: '\e9b0';
}
.unity_db_spark:before {
  content: '\e9c7';
}
.unity_excel:before {
  content: '\e9c8';
}
.unity_relate:before {
  content: '\e9f5';
}
.unity_token:before {
  content: '\e9f6';
}
.unity_user_admin:before {
  content: '\e931';
}
.unity_schedule:before {
  content: '\e923';
}
.unity_gplus:before {
  content: '\e960';
}
.unity_db_candi:before {
  content: '\e966';
}
.unity_db_carriots:before {
  content: '\e99e';
}
.unity_db_iot:before {
  content: '\e9a1';
}
.unity_data_format:before {
  content: '\e9f3';
}
.unity_form_chart:before {
  content: '\e9f4';
}
.unity_compress:before {
  content: '\e9d6';
}
.unity_csv_alt1:before {
  content: '\e9d7';
}
.unity_csv_alt:before {
  content: '\e9d8';
}
.unity_dashlet_chart:before {
  content: '\e9d9';
}
.unity_db_bigquery:before {
  content: '\e9da';
}
.unity_db_crm:before {
  content: '\e9db';
}
.unity_db_maria:before {
  content: '\e9dc';
}
.unity_db_oracle:before {
  content: '\e9dd';
}
.unity_db_postgres:before {
  content: '\e9de';
}
.unity_db_redshift:before {
  content: '\e9df';
}
.unity_db_salesforce:before {
  content: '\e9e0';
}
.unity_db_sql_server:before {
  content: '\e9e1';
}
.unity_db_sun:before {
  content: '\e9e2';
}
.unity_db_vertica:before {
  content: '\e9e3';
}
.unity_funnel_chart:before {
  content: '\e9e4';
}
.unity_link:before {
  content: '\e9e5';
}
.unity_randomize:before {
  content: '\e9e6';
}
.unity_refresh:before {
  content: '\e9e7';
}
.unity_source_csv:before {
  content: '\e9e8';
}
.unity_surface_3d_chart:before {
  content: '\e9e9';
}
.unity_text_bold:before {
  content: '\e9ea';
}
.unity_text_italic:before {
  content: '\e9eb';
}
.unity_text_justify_left:before {
  content: '\e9ec';
}
.unity_text_justify_middle:before {
  content: '\e9ed';
}
.unity_text_justify_right:before {
  content: '\e9ee';
}
.unity_treemap_chart:before {
  content: '\e9ef';
}
.unity_type_integer:before {
  content: '\e9f0';
}
.unity_type_string:before {
  content: '\e9f1';
}
.unity_wordcloud_chart:before {
  content: '\e9f2';
}
.unity_3dbar_chart:before {
  content: '\e900';
}
.unity_acrobat:before {
  content: '\e901';
}
.unity_add:before {
  content: '\e902';
}
.unity_richtext_chart:before {
  content: '\e903';
}
.unity_arrow_circle:before {
  content: '\e904';
}
.unity_asterix:before {
  content: '\e905';
}
.unity_avg:before {
  content: '\e906';
}
.unity_aws:before {
  content: '\e907';
}
.unity_bar_chart:before {
  content: '\e908';
}
.unity_checkbox:before {
  content: '\e909';
}
.unity_box_down:before {
  content: '\e90a';
}
.unity_box_left:before {
  content: '\e90b';
}
.unity_box_minus:before {
  content: '\e90c';
}
.unity_boxplot_chart:before {
  content: '\e90d';
}
.unity_box_plus:before {
  content: '\e90e';
}
.unity_box_right:before {
  content: '\e90f';
}
.unity_box_up:before {
  content: '\e910';
}
.unity_box:before {
  content: '\e911';
}
.unity_calendar:before {
  content: '\e912';
}
.unity_chat:before {
  content: '\e913';
}
.unity_select_none:before {
  content: '\e914';
}
.unity_select_all:before {
  content: '\e915';
}
.unity_select_inverse:before {
  content: '\e916';
}
.unity_check:before {
  content: '\e917';
}
.unity_circle_check:before {
  content: '\e918';
}
.unity_circle_markup:before {
  content: '\e919';
}
.unity_circle_minus:before {
  content: '\e91a';
}
.unity_circle_plus:before {
  content: '\e91b';
}
.unity_circle:before {
  content: '\e91c';
}
.unity_clear:before {
  content: '\e91d';
}
.unity_clock:before {
  content: '\e91e';
}
.unity_close:before {
  content: '\e91f';
}
.unity_db_monet:before {
  content: '\e920';
}
.unity_cloud:before {
  content: '\e921';
}
.unity_compass:before {
  content: '\e922';
}
.unity_compute:before {
  content: '\e924';
}
.unity_conical_flask:before {
  content: '\e925';
}
.unity_copy:before {
  content: '\e926';
}
.unity_css:before {
  content: '\e927';
}
.unity_csv:before {
  content: '\e928';
}
.unity_cube_1:before {
  content: '\e929';
}
.unity_cube_2:before {
  content: '\e92a';
}
.unity_cube_doc:before {
  content: '\e92b';
}
.unity_cube:before {
  content: '\e92c';
}
.unity_currency:before {
  content: '\e92d';
}
.unity_cursor:before {
  content: '\e92e';
}
.unity_cut:before {
  content: '\e92f';
}
.unity_dashboard:before {
  content: '\e930';
}
.unity_db_alt:before {
  content: '\e932';
}
.unity_db:before {
  content: '\e933';
}
.unity_date_time:before {
  content: '\e934';
}
.unity_delta_drill_chart:before {
  content: '\e935';
}
.unity_document:before {
  content: '\e936';
}
.unity_dollar:before {
  content: '\e937';
}
.unity_db_mysql:before {
  content: '\e938';
}
.unity_donut_chart:before {
  content: '\e939';
}
.unity_double_down_chevron:before {
  content: '\e93a';
}
.unity_double_left_chevron:before {
  content: '\e93b';
}
.unity_double_right_chevron:before {
  content: '\e93c';
}
.unity_double_up_chevron:before {
  content: '\e93d';
}
.unity_down_arrow:before {
  content: '\e93e';
}
.unity_down_chevron:before {
  content: '\e93f';
}
.unity_down_small:before {
  content: '\e940';
}
.unity_down:before {
  content: '\e941';
}
.unity_download_alt:before {
  content: '\e942';
}
.unity_download_alt1:before {
  content: '\e943';
}
.unity_download_files:before {
  content: '\e944';
}
.unity_download:before {
  content: '\e945';
}
.unity_drill_down:before {
  content: '\e946';
}
.unity_drive:before {
  content: '\e947';
}
.unity_dropbox:before {
  content: '\e948';
}
.unity_duplicate:before {
  content: '\e949';
}
.unity_edit_alt:before {
  content: '\e94a';
}
.unity_edit:before {
  content: '\e94b';
}
.unity_embed:before {
  content: '\e94c';
}
.unity_error:before {
  content: '\e94d';
}
.unity_euro:before {
  content: '\e94e';
}
.unity_exchange_doc:before {
  content: '\e94f';
}
.unity_expand_horizontal:before {
  content: '\e950';
}
.unity_facebook:before {
  content: '\e951';
}
.unity_file_add:before {
  content: '\e952';
}
.unity_file_download:before {
  content: '\e953';
}
.unity_file_upload:before {
  content: '\e954';
}
.unity_filter:before {
  content: '\e955';
}
.unity_folder_add:before {
  content: '\e956';
}
.unity_folder_download:before {
  content: '\e957';
}
.unity_folder_open:before {
  content: '\e958';
}
.unity_folder_upload:before {
  content: '\e959';
}
.unity_folder:before {
  content: '\e95a';
}
.unity_forward:before {
  content: '\e95b';
}
.unity_full_screen:before {
  content: '\e95c';
}
.unity_gauge_chart:before {
  content: '\e95d';
}
.unity_gear:before {
  content: '\e95e';
}
.unity_globe:before {
  content: '\e95f';
}
.unity_group:before {
  content: '\e961';
}
.unity_user_groups:before {
  content: '\e962';
}
.unity_hamburger:before {
  content: '\e963';
}
.unity_hand_down:before {
  content: '\e964';
}
.unity_hand_left:before {
  content: '\e965';
}
.unity_hand_right:before {
  content: '\e967';
}
.unity_hand_up:before {
  content: '\e968';
}
.unity_hash_tag:before {
  content: '\e969';
}
.unity_heatmap_chart:before {
  content: '\e96a';
}
.unity_help:before {
  content: '\e96b';
}
.unity_history:before {
  content: '\e96c';
}
.unity_home:before {
  content: '\e96d';
}
.unity_horizontal_bar_chart:before {
  content: '\e96e';
}
.unity_horizontal_bracket:before {
  content: '\e96f';
}
.unity_indent_alt:before {
  content: '\e970';
}
.unity_indent:before {
  content: '\e971';
}
.unity_info_circle:before {
  content: '\e972';
}
.unity_info:before {
  content: '\e973';
}
.unity_job_resubmit:before {
  content: '\e974';
}
.unity_job_run:before {
  content: '\e975';
}
.unity_job_suspend:before {
  content: '\e976';
}
.unity_jobs:before {
  content: '\e977';
}
.unity_layers:before {
  content: '\e978';
}
.unity_left_arrow:before {
  content: '\e979';
}
.unity_left_chevron:before {
  content: '\e97a';
}
.unity_left_small:before {
  content: '\e97b';
}
.unity_left:before {
  content: '\e97c';
}
.unity_lightning:before {
  content: '\e97d';
}
.unity_area_chart:before {
  content: '\e97e';
}
.unity_line_chart:before {
  content: '\e97f';
}
.unity_load_profile:before {
  content: '\e980';
}
.unity_local_system:before {
  content: '\e981';
}
.unity_lock:before {
  content: '\e982';
}
.unity_magic_wand:before {
  content: '\e983';
}
.unity_maps_chart:before {
  content: '\e984';
}
.unity_max:before {
  content: '\e985';
}
.unity_memory_card:before {
  content: '\e986';
}
.unity_message:before {
  content: '\e987';
}
.unity_min:before {
  content: '\e988';
}
.unity_minus:before {
  content: '\e989';
}
.unity_monitor:before {
  content: '\e98a';
}
.unity_move_to:before {
  content: '\e98b';
}
.unity_move:before {
  content: '\e98c';
}
.unity_navigate:before {
  content: '\e98d';
}
.unity_notepad:before {
  content: '\e98e';
}
.unity_notification:before {
  content: '\e98f';
}
.unity_numbered_list:before {
  content: '\e990';
}
.unity_numeral:before {
  content: '\e991';
}
.unity_open_new_window:before {
  content: '\e992';
}
.unity_options:before {
  content: '\e993';
}
.unity_ordered_list:before {
  content: '\e994';
}
.unity_pages:before {
  content: '\e995';
}
.unity_pie_chart:before {
  content: '\e996';
}
.unity_placeholder:before {
  content: '\e997';
}
.unity_polygon_chart:before {
  content: '\e998';
}
.unity_powerpoint:before {
  content: '\e999';
}
.unity_print:before {
  content: '\e99a';
}
.unity_properties:before {
  content: '\e99b';
}
.unity_property_alt:before {
  content: '\e99c';
}
.unity_radar_chart:before {
  content: '\e99d';
}
.unity_redo:before {
  content: '\e9a0';
}
.unity_rewind:before {
  content: '\e9a2';
}
.unity_right_arrow:before {
  content: '\e9a3';
}
.unity_right_chevron:before {
  content: '\e9a4';
}
.unity_right_small:before {
  content: '\e9a5';
}
.unity_right:before {
  content: '\e9a6';
}
.unity_rupee:before {
  content: '\e9a7';
}
.unity_bubble_chart:before {
  content: '\e9a8';
}
.unity_search:before {
  content: '\e9aa';
}
.unity_server:before {
  content: '\e9ab';
}
.unity_session_remote:before {
  content: '\e9ac';
}
.unity_share:before {
  content: '\e9ad';
}
.unity_Show_hide:before {
  content: '\e9ae';
}
.unity_slope_chart:before {
  content: '\e9af';
}
.unity_split_horizontal:before {
  content: '\e9b1';
}
.unity_split_vertical:before {
  content: '\e9b2';
}
.unity_square_markup:before {
  content: '\e9b3';
}
.unity_checkbox_empty:before {
  content: '\e9b4';
}
.unity_stack_overflow:before {
  content: '\e9b5';
}
.unity_stacked_bar_chart:before {
  content: '\e9b6';
}
.unity_star_selected:before {
  content: '\e9b7';
}
.unity_star:before {
  content: '\e9b8';
}
.unity_std:before {
  content: '\e9b9';
}
.unity_step_in:before {
  content: '\e9ba';
}
.unity_step_out:before {
  content: '\e9bb';
}
.unity_sum:before {
  content: '\e9bc';
}
.unity_table_chart:before {
  content: '\e9bd';
}
.unity_terminate:before {
  content: '\e9be';
}
.unity_text:before {
  content: '\e9bf';
}
.unity_thumbs_down:before {
  content: '\e9c0';
}
.unity_thumbs_up:before {
  content: '\e9c1';
}
.unity_tile_view:before {
  content: '\e9c2';
}
.unity_toggle_off:before {
  content: '\e9c3';
}
.unity_toggle_on:before {
  content: '\e9c4';
}
.unity_trash_can:before {
  content: '\e9c5';
}
.unity_tree_view:before {
  content: '\e9c6';
}
.unity_undo:before {
  content: '\e9c9';
}
.unity_unlock:before {
  content: '\e9ca';
}
.unity_up_arrow:before {
  content: '\e9cb';
}
.unity_up_chevron:before {
  content: '\e9cc';
}
.unity_up_small:before {
  content: '\e9cd';
}
.unity_up:before {
  content: '\e9ce';
}
.unity_upload_alt:before {
  content: '\e9cf';
}
.unity_upload_alt1:before {
  content: '\e9d0';
}
.unity_upload:before {
  content: '\e9d1';
}
.unity_user:before {
  content: '\e9d2';
}
.unity_var:before {
  content: '\e9d3';
}
.unity_view:before {
  content: '\e9d4';
}
.unity_waterfall_chart:before {
  content: '\e9d5';
}
